import './App.css';
import NavBar from '../src/components/NavBar';
import Home from '../src/pages/Home';
import Contact from '../src/pages/Contact';
import NotFound from '../src/pages/404';
import Footer from '../src/components/Footer'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'

function App() {
  return (
    <Router>
        <div>
          <NavBar/>
          <Routes>
            <Route exact path='/' element={<Home />} />
            <Route path='/contact' element={<Contact />} />
            <Route path='*' element={<NotFound />} />
          </Routes>
          <Footer />
        </div>
    </Router>
  );
}

export default App;
