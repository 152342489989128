import { CloudArrowUpIcon, LockClosedIcon, ServerIcon } from '@heroicons/react/20/solid'

export default function Example() {
  return (
    <div className="relative bg-cyan-900">
      <div className="mx-auto max-w-7xl lg:flex lg:justify-between lg:px-8 xl:justify-end">
        <div className="lg:flex lg:w-1/2 lg:shrink lg:grow-0 xl:absolute xl:inset-y-0 xl:right-1/2 xl:w-1/2">
          <div className="relative h-80 lg:-ml-8 lg:h-auto lg:w-full lg:grow xl:ml-0">
            <img
              className="absolute inset-0 h-full w-full bg-gray-50 object-cover"
              src="/img/office-working.jpg"
              alt=""
            />
          </div>
        </div>
        <div className="px-6 lg:contents">
          <div className="mx-auto max-w-2xl pb-24 pt-16 sm:pb-32 sm:pt-20 lg:ml-8 lg:mr-0 lg:w-full lg:max-w-lg lg:flex-none lg:pt-32 xl:w-1/2">
            <p className="text-base font-semibold leading-7 text-cyan-400">Machine Learning / Artificial Intelligence</p>
            <h1 className="mt-2 text-3xl font-bold tracking-tight text-white sm:text-4xl">Machine Learning and AI Services</h1>
            <p className="mt-6 text-xl leading-8 text-gray-300">
            We harness the power of machine learning and artificial intelligence to transform data into actionable insights, enabling businesses to make smarter decisions, automate processes, and unlock new opportunities. 
            Our machine learning and AI services are designed to help organizations leverage the full potential of data-driven solutions.
            </p>
            <div className="mt-10 max-w-xl text-base leading-7 text-gray-300 lg:max-w-none">
              <p>
              We believe that the successful application of machine learning and AI begins with a deep understanding of your business objectives and data. Our approach involves:
              </p>
              <ul role="list" className="mt-8 space-y-8 text-gray-600">
                <li className="flex gap-x-3">
                  <CloudArrowUpIcon className="mt-1 h-5 w-5 flex-none text-cyan-400" aria-hidden="true" />
                  <span className='text-gray-200'>
                    <strong className="font-semibold text-white">Data Assessment and Strategy: </strong>
                    We start by analyzing your data sources and developing a data strategy. We identify the data you have, the data you need, and how to collect, store, and manage it effectively.
                  </span>
                </li>
                <li className="flex gap-x-3">
                  <LockClosedIcon className="mt-1 h-5 w-5 flex-none text-cyan-400" aria-hidden="true" />
                  <span className='text-gray-200'>
                    <strong className="font-semibold text-white">Model Development: </strong>
                    Our data scientists and machine learning engineers work closely to design and develop custom machine learning models that align with your business goals. 
                    We use a variety of techniques, including supervised and unsupervised learning, deep learning, and natural language processing (NLP).
                  </span>
                </li>
                <li className="flex gap-x-3">
                  <ServerIcon className="mt-1 h-5 w-5 flex-none text-cyan-400" aria-hidden="true" />
                  <span className='text-gray-200'>
                    <strong className="font-semibold text-white">Integration: </strong>
                    We seamlessly integrate machine learning models into your existing systems and workflows, ensuring a smooth transition and minimal disruption.
                  </span>
                </li>
                <li className="flex gap-x-3">
                  <ServerIcon className="mt-1 h-5 w-5 flex-none text-cyan-400" aria-hidden="true" />
                  <span className='text-gray-200'>
                    <strong className="font-semibold text-white">Testing and Validation: </strong>
                    Rigorous testing and validation are conducted to ensure the accuracy and reliability of machine learning models. 
                    We fine-tune and optimize models for optimal performance.
                  </span>
                </li>
                <li className="flex gap-x-3">
                  <ServerIcon className="mt-1 h-5 w-5 flex-none text-cyan-400" aria-hidden="true" />
                  <span className='text-gray-200'>
                    <strong className="font-semibold text-white">Deployment and Monitoring: </strong>
                    We deploy models in production environments and continually monitor their performance, making necessary adjustments and updates as data evolves.
                  </span>
                </li>
              </ul>
            <div className='prose prose-indigo mx-auto mt-12 text-gray-300 lg:col-start-1 lg:row-start-1 lg:max-w-none'>
                <h3 className='text-gray-300'>Our Expertise</h3>
                <p>Our team of developers has expertise in a wide range of technologies, including but not limited to:</p>
                <ul>
                    <li><strong className='text-white'>Supervised Learning: </strong> We build predictive models for classification and regression tasks, such as customer churn prediction, image recognition, and recommendation systems.</li>
                    <li><strong className='text-white'>Unsupervised Learning: </strong> Our expertise extends to clustering and dimensionality reduction techniques, enabling us to uncover hidden patterns in your data.</li>
                    <li><strong className='text-white'>Deep Learning: </strong> We develop neural networks for tasks like image and speech recognition, natural language processing, and anomaly detection.</li>
                    <li><strong className='text-white'>NLP (Natural Language Processing): </strong> Our NLP solutions enable sentiment analysis, chatbots, language translation, and content summarization.</li>
                    <li><strong className='text-white'>Reinforcement Learning: </strong> We implement reinforcement learning algorithms for dynamic decision-making and optimization problems.</li>
                </ul>
            </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
