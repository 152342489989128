import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

i18next
    .use(initReactI18next)
    .use(LanguageDetector)
    .init({
        fallbackLng: 'en',
        resources: {
            en: {
                translation: {
                home: 'Home',
                clear: 'Clear',
                name: 'Name',
                namePlaceholder: 'Please type your name',
                nameValidation: 'Name length must be larger than 3.',
                email: 'Email',
                emailPlaceholder: 'example@gmail.com',
                emailValidation: 'Incorrect email format.',
                subject: 'Subject',
                subjectValidation: 'Subject must be larger than 3.',
                subjectPlaceholder: 'Subject',
                phone: 'Phone',
                phonePlaceholder: '###-###-####',
                phoneValidation: 'Incorrect phone format.',
                message: 'Message',
                messagePlaceholder: 'Send a message',
                messageValidation: 'Message must be larger than 3.',
                send: 'Send',
                about: 'About',
                contact: 'Contact',
                contactDescription: 'Any questions? Contact us for more information about our services.',
                language: 'Language',
                staticWebsiteHosting: 'Software Development & Consulting Services',
                staticWebsiteHostingDescription: `A landing page website is a type of web design that displays the same content to every visitor. 
                It is the most basic type of web design and can be used to display information such as a company's contact information, a personal blog, or an online portfolio. 
                Think of it as an online brochure, it shows the same information to everyone who visits it.`,
                slogan: "We are a leading software development company specializing in cloud-based solutions and services.",
                services: 'Services',
                whatDoWeOffer: 'What do we offer?',
                servicesDescription: 'We offer a wide range of consulting services and web application development using cloud platforms like AWS (Amazon Web Services) and Azure (Microsoft Azure).',
                softwareApplicationDevelopmentTitle: "Software Application Development",
                softwareApplicationDevelopmentDescription: "Our skilled developers create custom web applications, leveraging the power and scalability of AWS and Azure. We build solutions for e-commerce, content management, SaaS, and more.",
                machineLearningTitle: 'Machine Learning/AI',
                machineLearningDescription: 'Machine Learning/AI',
                support: 'Support',
                supportDescription: `Providing support for developing and maintaining websites includes helping with design, coding, and updates. 
                Providing support for Shopify stores includes assisting with setup, customization, product management and platform usage. 
                Providing support for SEO includes helping improve website visibility in search results, by optimizing website structure and content, building backlinks and providing guidance on keyword usage.`,
                consultingServicesTitle: 'Consulting Services',
                consultingServicesDescription: "Our expert consultants provide tailored solutions to help businesses harness the full potential of cloud computing. We offer cloud strategy development, architecture design, cost optimization, and more.",
                shopifyStore: 'Shopify Website',
                shopifyDescription: 'Shopify is an e-commerce platform that allows businesses and individuals to create and manage an online store. It offers features for customizable website design, product management, secure payment processing, order and shipping management, marketing and SEO, and mobile optimization.',
                seo: 'Search Engine Optimization (SEO)',
                socialMediaMarketingServiceDescription: 'Our social media marketing service provides a comprehensive solution for businesses looking to maximize their online presence, from strategy to content creation to platform management and performance reporting.',
                seoDescription: 'The ultimate goal of SEO is to increase the visibility and credibility of a website, making it more likely that users will find it when searching for relevant information.',
                sslCertificate: 'SSL Certificate',
                domainName: 'Custom Domain',
                multiLanguage: 'Multi Language Support',
                pricing: 'Pricing',
                responsiveDesign: 'Responsive Design',
                secureHosting: 'Secure hosting',
                pricingTitle: 'The right price for your business',
                pricingDescription: 'Choose or create your own personalized landing page web design, ecommerce or marketing package with our flexible options and customization services, designed to meet your specific needs and budget.',
                viewPricing: 'View our Pricing',
                contactUs: 'Contact Us',
                socialMediaMarketing: 'Social Media Marketing',
                landingPage: 'Landing Website Design',
                ecommerce: 'E-Commerce',
                socialMediaMarketingDescription: 'Unleash the full potential of your brand with our comprehensive social media marketing service, delivering innovative solutions and targeted strategies to drive growth and increase customer engagement. Platforms included:',
                staticWebsiteDescription: 'Make a lasting first impression with our expertly crafted landing page website service, designed to captivate your audience and convert visitors into customers.',
                ecommerceDescription: 'Transform your online shopping experience with our robust ecommerce website solution, offering seamless navigation, secure transactions, and personalized features to drive sales and grow your business.',
                customPackage: 'Custom Package',
                createYourOwnPackage: 'Customize your service package by choosing two or more of our offerings to create the perfect solution for your unique needs and goals.',
                getQuote: 'Get Quote',
                startingFrom: 'Starting from',
                whatMakesUsDifferent: 'What Makes Us Different',
                whatMakesUsDifferentDescription: `
                At StaticServed, we pride ourselves on delivering exceptional value and service to our clients. 
                What sets us apart from any other web design company is our commitment to customer satisfaction.`,
                whatMakesUsDifferentDescriptionOne: `
                Our team of experts work closely with each client to understand their unique requirements and goals, and we design custom packages that cater to their specific needs. 
                We also offer 24/7 support and regular website maintenance to ensure maximum uptime and optimal performance.`,
                whatMakesUsDifferentDescriptionTwo: `
                Additionally, our clients can request updates to their websites so we can do it for them, saving time and resources. 
                Overall, our focus on quality, affordability, and personalized service sets us apart and makes us the ideal choice for all your web design needs.`,
                weAreHeretoHelp: `We are here to help`,
                weAreHeretoHelpDescription: `
                We believe in taking the hassle out of website hosting. 
                Our team of experts is here to help you every step of the way and take care of the technical details, so you can focus on what's important - your business. 
                From design and development to maintenance and support, we're here to do it all for you. 
                Our commitment to customer satisfaction is second to none, and we're always available to answer any questions and address any concerns. 
                With StaticServed, you can trust that your website is in good hands and that we're dedicated to helping you succeed.`,
                quoteLink: `It has been established that a significant proportion of consumers, approximately 75%, assess the credibility of a business based on its website design.`,
                quote: "With this in mind, our aim is to offer aesthetically pleasing, intuitive and responsive websites that are capable of capturing the attention of prospective customers.",
                coFounder: 'Co-Founder at StaticServed',
                allRightsReserved: 'All rights reserved.',
                contactInformation: 'Contact Information',
                optional: 'Optional',
                frequentlyAskedQuestions: 'Frequently Asked Questions',
                whatIsAStaticWebsiteQuestion: 'What is a Landing Page Website?',
                whatIsAStaticWebsiteAnswer: `A landing page website is a type of website that displays the same content to every visitor. 
                It is the most basic type of website and can be used to display information such as a company's contact information, a personal blog, or an online portfolio. 
                Think of it as an online brochure, it shows the same information to everyone who visits it.`,
                canWeCreateOurOwnPackageQuestion: 'Can we create our own package?',
                canWeCreateOurOwnPackageAnswer: 'Yes, you can create your own personalized landing page website or shopify website package with our flexible options and customization services, designed to meet your specific needs and budget.',
                goBackHome: 'Go Back Home',
                pageNotFound: 'Page not found.',
                pageNotFoundDescription: 'Please check the URL in the address bar and try again.',
                notificationSuccessTitle: 'Success',
                notificationErrorTitle: 'Error',
                notificationSuccessMessage: 'Email was sent successfully.',
                notificationErrorMessage: 'An error has occured.',
                demo: 'Demo',
                demoDescription: 'Join us for a live demo of our landing pages, where we will showcase their impressive design and user-friendly features.',
                viewDemo: 'View Demo',
                restaurantDemo: 'Restaurant',
                restaurantDemoDescription: 'Take a look at our restaurant landing page demo.',
                comingSoonDemo: 'Coming Soon',
                comingSoonDemoDescription: 'More demos coming soon!'
                }
            },
            es: {
                translation: {
                home: 'Hogar',
                clear: 'Borrar',
                name: 'Nombre',
                namePlaceholder: 'Por favor escriba su nombre',
                nameValidation: 'La longitud del nombre debe ser mayor que 3.',
                email: 'Correo Electrónico',
                emailPlaceholder: 'ejemplo@gmail.com',
                emailValidation: 'Formato de correo electrónico incorrecto.',
                subject: 'Asunto',
                subjectValidation: 'La longitud del asunto debe ser mayor que 3.',
                subjectPlaceholder: 'Asunto',
                phone: 'Teléfono',
                phonePlaceholder: '###-###-####',
                phoneValidation: 'Formato de teléfono incorrecto.',
                message: 'Mensaje',
                messagePlaceholder: 'Mande un mensaje',
                messageValidation: 'La longitud del mensaje debe ser mayor que 3.',
                send: 'Enviar',
                about: 'Sobre Nosotros',
                contact: 'Contacto',
                contactDescription: '¿Alguna pregunta? Contáctese con nosotros para mas información sobre nuestros servicios.',
                language: 'Idioma',
                staticWebsiteHosting: 'Servicios de Consultoría & Desarrollo de Software',
                staticWebsiteHostingDescription: `Una pagina web estatica es un tipo de paginas web que muestra el mismo contenido a todos los visitantes. 
                Es el tipo de paginas web más básico y se puede utilizar para mostrar información como la información de contacto de una empresa, un blog personal o una cartera en línea. 
                Piense en ello como un folleto en línea, muestra la misma información a todos los que lo visitan.`,
                slogan: 'Somos una empresa líder en desarrollo de software que se especializa en soluciones y servicios basados ​​en la nube.',
                services: 'Servicios',
                whatDoWeOffer: '¿Qué ofrecemos?',
                servicesDescription: 'Ofrecemos una amplia gama de servicios de consultoría y desarrollo de aplicaciones web utilizando plataformas en la nube como AWS (Amazon Web Services) y Azure (Microsoft Azure).',
                softwareApplicationDevelopmentTitle: "Desarrollo de Aplicaciones de Software",
                softwareApplicationDevelopmentDescription: "Nuestros desarrolladores capacitados crean aplicaciones web personalizadas, aprovechando el poder y la escalabilidad de AWS y Azure. Creamos soluciones para comercio electrónico, gestión de contenidos, SaaS y más.",
                machineLearningTitle: 'Machine Learning/AI',
                machineLearningDescription: 'Machine Learning/AI',
                supportDescription: `Brindar soporte para desarrollar y mantener paginas web estaticas incluye ayudar con el diseño, la codificación y las actualizaciones.
                Proporcionar soporte para las tiendas Shopify incluye ayudar con la configuración, la personalización, la gestión de productos y el uso de la plataforma.
                Brindar soporte para SEO incluye ayudar a mejorar la visibilidad de la pagina web en los resultados de búsqueda, optimizando la estructura y el contenido de la pagina web, creando vínculos de retroceso y brindando orientación sobre el uso de palabras clave.
                `,
                consultingServicesTitle: 'Servicios de Consultoría',
                consultingServicesDescription: "Nuestros consultores expertos brindan soluciones personalizadas para ayudar a las empresas a aprovechar todo el potencial de la computación en la nube. Ofrecemos desarrollo de estrategias en la nube, diseño de arquitectura, optimización de costos y más.",
                shopifyStore: 'Tienda Shopify',
                shopifyDescription: 'Shopify es una plataforma de comercio electrónico que permite a empresas e individuos crear y administrar una tienda en línea. Ofrece funciones para el diseño de tiendas personalizables, gestión de productos, procesamiento de pagos seguros, gestión de pedidos y envíos, marketing y SEO, y optimización móvil.',
                seo: 'Optimización de motores de búsqueda (SEO)',
                seoDescription: 'El objetivo final del SEO es aumentar la visibilidad y la credibilidad de una pagina web, haciendo que sea más probable que los usuarios lo encuentren cuando busquen información relevante.',
                socialMediaMarketingServiceDescription: 'Nuestro servicio de manejo de redes sociales brinda una solución integral para las empresas que buscan maximizar su presencia en línea, desde la estrategia hasta la creación de contenido, la gestión de la plataforma y los informes de rendimiento.',
                sslCertificate: 'Certificado SSL',
                domainName: 'Dominio Personalizado',
                multiLanguage: 'Soporte Multilingüe',
                pricing: 'Precios',
                responsiveDesign: 'Diseño Receptivo',
                secureHosting: 'Alojamiento seguro',
                pricingTitle: 'El precio justo para tu negocio',
                pricingDescription: 'Elija o cree su propio paquete de pagina web estatica personalizado, comercio electrónico o mercadeo con nuestras flexibles opciones y servicios de personalización, diseñados para satisfacer sus necesidades y presupuesto específico.',
                viewPricing: 'Ver nuestros precios',
                contactUs: 'Contáctenos',
                socialMediaMarketing: 'Manejo de Redes Sociales',
                landingPage: 'Pagina Web Estatica',
                ecommerce: 'Comercio Electrónico',
                socialMediaMarketingDescription: 'Libere todo el potencial de su marca con nuestro servicio integral de manejo de redes sociales, brindando soluciones innovadoras y estrategias específicas para impulsar el crecimiento y aumentar la participación del cliente. Plataformas incluidas:',
                staticWebsiteDescription: 'Causa una primera impresión duradera con nuestro servicio de pagina web, diseñado para cautivar a tu audiencia y convertir a los visitantes en clientes.',
                ecommerceDescription: 'Transforme su experiencia de compra en línea con nuestra sólida solución de paginas web de comercio electrónico, que ofrece una navegación fluida, transacciones seguras y funciones personalizadas para impulsar las ventas y hacer crecer su negocio.',
                customPackage: 'Paquete Personalizado',
                createYourOwnPackage: 'Personalice su paquete de servicios eligiendo dos o más de nuestras ofertas para crear la solución perfecta para sus necesidades y objetivos únicos.',
                getQuote: 'Obtener Cotización',
                startingFrom: 'Empezando desde',
                whatMakesUsDifferent: 'Qué nos diferencia',
                whatMakesUsDifferentDescription: `
                En StaticServed, nos enorgullecemos de brindar un valor y un servicio excepcionales a nuestros clientes.
                Lo que nos diferencia de otras empresas de alojamiento de paginas web estaticas es nuestro compromiso con la satisfacción del cliente.`,
                whatMakesUsDifferentDescriptionOne: `
                Nuestro equipo de expertos trabaja en estrecha colaboración con cada cliente para comprender sus requisitos y objetivos únicos, y diseñamos paquetes personalizados que se adaptan a sus necesidades específicas.
                También ofrecemos soporte 24/7 y mantenimiento regular de la pagina web para garantizar el máximo tiempo de actividad y un rendimiento óptimo.`,
                whatMakesUsDifferentDescriptionTwo: `
                Además, nuestros clientes pueden solicitar actualizaciones de sus paginas web para que nosotros lo hagamos por ellos, ahorrándoles tiempo y recursos.
                En general, nuestro enfoque en la calidad, la asequibilidad y el servicio personalizado nos distingue y nos convierte en la opción ideal para todas sus necesidades de alojamiento de paginas web estaticas.`,
                weAreHeretoHelp: `Estamos aqui para ayudar`,
                weAreHeretoHelpDescription: `
                Creemos en eliminar las molestias del alojamiento de paginas web.
                Nuestro equipo de expertos está aquí para ayudarlo en cada paso del camino y cuidar los detalles técnicos, para que pueda concentrarse en lo que es importante: su negocio.
                Desde diseño y desarrollo hasta mantenimiento y soporte, estamos aquí para hacerlo todo por usted.
                Nuestro compromiso con la satisfacción del cliente es insuperable, y siempre estamos disponibles para responder cualquier pregunta y abordar cualquier inquietud.
                Con StaticServed, puede confiar en que su pagina web está en buenas manos y que estamos dedicados a ayudarlo a tener éxito.`,
                quoteLink: `Se ha establecido que una proporción importante de los consumidores, aproximadamente el 75%, evalúa la credibilidad de una empresa en función del diseño de su pagina web.`,
                quote: "Con esto en mente, nuestro objetivo es ofrecer paginas web esteticamente agradables, intuitivos y receptivos que sean capaces de captar la atención de los posibles clientes.",
                coFounder: 'Co-Fundador de StaticServed',
                allRightsReserved: 'Todos los derechos reservados.',
                contactInformation: 'Información de contacto',
                optional: 'Opcional',
                frequentlyAskedQuestions: 'Preguntas frecuentes',
                whatIsAStaticWebsiteQuestion: '¿Qué es una pagina web estática?',
                whatIsAStaticWebsiteAnswer: `Una pagina web estática es un tipo de paginas web que muestra el mismo contenido a todos los visitantes. 
                Es el tipo de paginas web más básico y se puede utilizar para mostrar información como la información de contacto de una empresa, un blog personal o una cartera en línea. 
                Piense en ello como un folleto en línea, muestra la misma información a todos los que lo visitan.`,
                canWeCreateOurOwnPackageQuestion: '¿Podemos crear nuestro propio paquete?',
                canWeCreateOurOwnPackageAnswer: 'Sí, puede crear su propio paquete de paginas web estáticas o tienda shopify personalizado con nuestras opciones flexibles y servicios de personalización, diseñados para satisfacer sus necesidades y presupuesto específicos.',
                goBackHome: 'Regresar a la pagina principal',
                pageNotFound: 'Página no encontrada.',
                pageNotFoundDescription: 'Verifique la URL en la barra de direcciones y vuelva a intentarlo.',
                notificationSuccessTitle: 'Éxito',
                notificationErrorTitle: 'Error',
                notificationSuccessMessage: 'El correo electrónico fue enviado exitosamente.',
                notificationErrorMessage: 'Ha ocurrido un error.',
                demo: 'Demostración',
                demoDescription: 'Únase a nosotros para una demostración en vivo de nuestras páginas de destino, donde mostraremos su impresionante diseño y características fáciles de usar.',
                viewDemo: 'Ver Demostración',
                restaurantDemo: 'Restaurante',
                restaurantDemoDescription: 'Eche un vistazo a nuestra demostración de la página de destino de un restaurante.',
                comingSoonDemo: 'Muy Pronto',
                comingSoonDemoDescription: '¡Más demostraciones próximamente!'
                }
            }
        }
    })