import { CloudArrowUpIcon, LockClosedIcon, ServerIcon } from '@heroicons/react/20/solid'

export default function Example() {
  return (
    <div className="relative bg-cyan-900">
      <div className="mx-auto max-w-7xl lg:flex lg:justify-between lg:px-8 xl:justify-end">
        <div className="lg:flex lg:w-1/2 lg:shrink lg:grow-0 xl:absolute xl:inset-y-0 xl:right-1/2 xl:w-1/2">
          <div className="relative h-80 lg:-ml-8 lg:h-auto lg:w-full lg:grow xl:ml-0">
            <img
              className="absolute inset-0 h-full w-full bg-gray-50 object-cover"
              src="https://images.unsplash.com/photo-1559136555-9303baea8ebd?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&crop=focalpoint&fp-x=.4&w=2560&h=3413&&q=80"
              alt=""
            />
          </div>
        </div>
        <div className="px-6 lg:contents">
          <div className="mx-auto max-w-2xl pb-24 pt-16 sm:pb-32 sm:pt-20 lg:ml-8 lg:mr-0 lg:w-full lg:max-w-lg lg:flex-none lg:pt-32 xl:w-1/2">
            <p className="text-base font-semibold leading-7 text-cyan-400">Software Development</p>
            <h1 className="mt-2 text-3xl font-bold tracking-tight text-white sm:text-4xl">Custom Software Development Services</h1>
            <p className="mt-6 text-xl leading-8 text-gray-300">
            At Static Served, we pride ourselves on delivering cutting-edge custom software solutions that cater to the unique needs of our clients. 
            Our software development services are designed to empower businesses, streamline operations, and drive growth. 
            With a team of highly skilled developers and a proven track record of success, we are your trusted partner for all your software development needs.
            </p>
            <div className="mt-10 max-w-xl text-base leading-7 text-gray-300 lg:max-w-none">
              <p>
              We understand that each business is unique, and one-size-fits-all solutions often fall short of expectations. 
              That's why we take a client-centric approach to every project. Our process includes:
              </p>
              <ul role="list" className="mt-8 space-y-8 text-gray-600">
                <li className="flex gap-x-3">
                  <CloudArrowUpIcon className="mt-1 h-5 w-5 flex-none text-cyan-400" aria-hidden="true" />
                  <span className='text-gray-200'>
                    <strong className="font-semibold text-white">Discovery and Analysis: </strong>
                    We start by thoroughly understanding your business, goals, and challenges. 
                    Our team conducts a comprehensive analysis to identify the best technology stack and development approach.
                  </span>
                </li>
                <li className="flex gap-x-3">
                  <LockClosedIcon className="mt-1 h-5 w-5 flex-none text-cyan-400" aria-hidden="true" />
                  <span className='text-gray-200'>
                    <strong className="font-semibold text-white">Custom Design: </strong>
                    Our expert designers create intuitive and user-friendly interfaces, ensuring that your software not only functions flawlessly but also provides an exceptional user experience.
                  </span>
                </li>
                <li className="flex gap-x-3">
                  <ServerIcon className="mt-1 h-5 w-5 flex-none text-cyan-400" aria-hidden="true" />
                  <span className='text-gray-200'>
                    <strong className="font-semibold text-white">Development: </strong>
                    Our developers leverage the latest technologies and best practices to bring your vision to life. 
                    We adhere to agile development methodologies, enabling us to adapt to changing requirements and deliver results quickly.
                  </span>
                </li>
                <li className="flex gap-x-3">
                  <ServerIcon className="mt-1 h-5 w-5 flex-none text-cyan-400" aria-hidden="true" />
                  <span className='text-gray-200'>
                    <strong className="font-semibold text-white">Quality Assurance: </strong>
                    Rigorous testing and quality assurance processes are an integral part of our development cycle. 
                    We ensure that your software is robust, secure, and bug-free.
                  </span>
                </li>
                <li className="flex gap-x-3">
                  <ServerIcon className="mt-1 h-5 w-5 flex-none text-cyan-400" aria-hidden="true" />
                  <span className='text-gray-200'>
                    <strong className="font-semibold text-white">Deployment and Optimization: </strong>
                    We handle the deployment of your software, making sure it runs smoothly in your environment. 
                    Post-launch, we provide ongoing support and optimization to keep your software up-to-date and performing at its best.
                  </span>
                </li>
              </ul>
            <div className='prose prose-indigo mx-auto mt-12 text-gray-300 lg:col-start-1 lg:row-start-1 lg:max-w-none'>
                <h3 className='text-gray-300'>Our Expertise</h3>
                <p>Our team of developers has expertise in a wide range of technologies, including but not limited to:</p>
                <ul>
                    <li><strong className='text-white'>Web Development: </strong> We build responsive and scalable web applications using modern frameworks like React, Angular, and Vue.js.</li>
                    <li><strong className='text-white'>Mobile App Development: </strong> Whether you need iOS, Android, or cross-platform mobile apps, we have the skills to create high-performing mobile solutions.</li>
                    <li><strong className='text-white'>Database Design and Management: </strong> We design efficient and secure database systems, utilizing SQL and NoSQL databases.</li>
                    <li><strong className='text-white'>Cloud Integration: </strong> We specialize in integrating software with cloud platforms like AWS and Azure for enhanced scalability and flexibility.</li>
                    <li><strong className='text-white'>Custom Software Solutions: </strong> From CRM systems to ERP software, we create tailor-made solutions to address specific business needs.</li>
                </ul>
            </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
