import { CloudArrowUpIcon, LockClosedIcon, ServerIcon } from '@heroicons/react/20/solid'

export default function Example() {
  return (
    <div className="relative isolate overflow-hidden bg-gray-100 px-6 py-24 sm:py-32 lg:overflow-visible lg:px-0">
      <div className="absolute inset-0 -z-10 overflow-hidden">
        <svg
          className="absolute left-[max(50%,25rem)] top-0 h-[64rem] w-[128rem] -translate-x-1/2 stroke-gray-200 [mask-image:radial-gradient(64rem_64rem_at_top,white,transparent)]"
          aria-hidden="true"
        >
          <defs>
            <pattern
              id="e813992c-7d03-4cc4-a2bd-151760b470a0"
              width={200}
              height={200}
              x="50%"
              y={-1}
              patternUnits="userSpaceOnUse"
            >
              <path d="M100 200V.5M.5 .5H200" fill="none" />
            </pattern>
          </defs>
          <svg x="50%" y={-1} className="overflow-visible fill-gray-50">
            <path
              d="M-100.5 0h201v201h-201Z M699.5 0h201v201h-201Z M499.5 400h201v201h-201Z M-300.5 600h201v201h-201Z"
              strokeWidth={0}
            />
          </svg>
          <rect width="100%" height="100%" strokeWidth={0} fill="url(#e813992c-7d03-4cc4-a2bd-151760b470a0)" />
        </svg>
      </div>
      <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 lg:mx-0 lg:max-w-none lg:grid-cols-2 lg:items-start lg:gap-y-10">
        <div className="lg:col-span-2 lg:col-start-1 lg:row-start-1 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8">
          <div className="lg:pr-4">
            <div className="lg:max-w-lg">
              <p className="text-base font-semibold leading-7 text-cyan-600">Consulting</p>
              <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Consulting Services</h1>
              <p className="mt-6 text-xl leading-8 text-gray-700">
              We offer comprehensive consulting services that empower businesses to navigate complex challenges, optimize operations, and achieve strategic goals. 
              With a team of experienced consultants and a commitment to delivering practical solutions, we are your trusted partner for consulting services tailored to your unique needs.
              </p>
            </div>
          </div>
        </div>
        <div className="-ml-12 -mt-12 p-12 lg:sticky lg:top-4 lg:col-start-2 lg:row-span-2 lg:row-start-1 lg:overflow-hidden">
          <img
            className="w-[48rem] max-w-none rounded-xl bg-gray-900 shadow-xl ring-1 ring-gray-400/10 sm:w-[57rem]"
            src="/img/office.jpg"
            alt=""
          />
        </div>
        <div className="lg:col-span-2 lg:col-start-1 lg:row-start-2 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8">
          <div className="lg:pr-4">
            <div className="max-w-xl text-base leading-7 text-gray-700 lg:max-w-lg">
              <p>
                Our team of consultants possesses a wide range of expertise in various areas, including:
              </p>
              <ul role="list" className="mt-8 space-y-8 text-gray-600">
                <li className="flex gap-x-3">
                  <CloudArrowUpIcon className="mt-1 h-5 w-5 flex-none text-cyan-600" aria-hidden="true" />
                  <span>
                    <strong className="font-semibold text-gray-900">Strategic Planning: </strong> 
                    We help you define your vision, mission, and long-term objectives, and develop a roadmap to achieve them.
                  </span>
                </li>
                <li className="flex gap-x-3">
                  <LockClosedIcon className="mt-1 h-5 w-5 flex-none text-cyan-600" aria-hidden="true" />
                  <span>
                    <strong className="font-semibold text-gray-900">Digital Transformation: </strong>
                    Our consultants guide your digital transformation journey, leveraging emerging technologies and best practices.
                  </span>
                </li>
                <li className="flex gap-x-3">
                  <ServerIcon className="mt-1 h-5 w-5 flex-none text-cyan-600" aria-hidden="true" />
                  <span>
                    <strong className="font-semibold text-gray-900">Process Improvement: </strong>
                    We identify inefficiencies and bottlenecks in your processes and recommend streamlined solutions for improved productivity.
                  </span>
                </li>
                <li className="flex gap-x-3">
                  <LockClosedIcon className="mt-1 h-5 w-5 flex-none text-cyan-600" aria-hidden="true" />
                  <span>
                    <strong className="font-semibold text-gray-900">Change Management: </strong>
                    Successful change requires effective change management strategies. We help your organization navigate transitions smoothly.
                  </span>
                </li>
                <li className="flex gap-x-3">
                  <ServerIcon className="mt-1 h-5 w-5 flex-none text-cyan-600" aria-hidden="true" />
                  <span>
                    <strong className="font-semibold text-gray-900">IT Consulting: </strong>
                    From IT strategy to software selection and implementation, we provide IT consulting to optimize your technology investments.
                  </span>
                </li>
                <li className="flex gap-x-3">
                  <ServerIcon className="mt-1 h-5 w-5 flex-none text-cyan-600" aria-hidden="true" />
                  <span>
                    <strong className="font-semibold text-gray-900">Data Analytics: </strong>
                    We leverage data analytics to provide actionable insights for informed decision-making and business growth.
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
