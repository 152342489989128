import * as React from "react";
import { Link } from "react-router-dom";
import { Fragment } from 'react'
import { Popover, Transition } from '@headlessui/react'
import {
  Bars3Icon,
  XMarkIcon,
  UserCircleIcon,
  EnvelopeIcon,
  LanguageIcon,
  FolderIcon
} from '@heroicons/react/24/outline'
import DropDown from '../components/Dropdown'
import { useTranslation } from 'react-i18next';


export default function NavBar() {

  const { t } = useTranslation()

  return (
    <Popover className="relative bg-cyan-900" >
      <div className="mx-auto max-w-7xl px-6">
        <div className="flex items-center justify-between border-b-2 border-gray-100 py-6 md:justify-start md:space-x-10" style={{borderStyle: "none"}}>
          <div className="flex justify-start lg:w-0 lg:flex-1">
            <Link to="/">
              <span className="sr-only">StaticServed</span>
              <img className="" height={"auto"} width={"200"} style={{ verticalAlign: "" }} src="/img/static-served-logo.png" alt="Static Served Logo" />
            </Link>
          </div>
          <div className="-my-2 -mr-2 md:hidden">
            <Popover.Button className="inline-flex items-center justify-center rounded-md bg-cyan-900 p-2 text-gray-100 hover:text-gray-200 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-cyan-500">
              <span className="sr-only">Open menu</span>
              <Bars3Icon className="h-6 w-6" aria-hidden="true" style={{ marginTop: "1rem" }} />
            </Popover.Button>
          </div>
          <Popover.Group as="nav" className="hidden space-x-10 md:flex" style={{ marginTop: "1rem" }}>
            <a href="/" className="text-base font-medium text-gray-100 hover:text-gray-200">
            {t('home')}
            </a>
            <a href="/contact" className="text-base font-medium text-gray-100 hover:text-gray-200">
              {t('contact')}
            </a>
            <DropDown/>
          </Popover.Group>
        </div>
      </div>

      <Transition
        as={Fragment}
        enter="duration-200 ease-out"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="duration-100 ease-in"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        <Popover.Panel focus className="absolute inset-x-0 top-0 origin-top-right transform p-2 transition md:hidden" style={{zIndex: 1000}}>
          <div className="divide-y-2 divide-gray-50 rounded-lg bg-cyan-900 shadow-lg ring-1 ring-black ring-opacity-5">
            <div className="px-5 pt-5 pb-6">
              <div className="flex items-center justify-between">
                <div>
                <img className="" height={"auto"} width={"150"} src="/img/static-served-logo.png" alt="Static Served Logo" />
                </div>
                <div className="-mr-2">
                  <Popover.Button className="inline-flex items-center justify-center rounded-md bg-cyan-900 p-2 text-gray-100 hover:bg-cyan-600 hover:text-gray-200 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-cyan-500">
                    <span className="sr-only">Close menu</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </Popover.Button>
                </div>
              </div>
              <div className="mt-6">
                <nav className="grid gap-y-8">
                    <a
                      key='home'
                      href='/'
                      className="-m-3 flex items-center rounded-md p-3 hover:bg-cyan-600"
                    >
                      <UserCircleIcon className="h-6 w-6 flex-shrink-0 text-white" aria-hidden="true" />
                      <span className="ml-4 text-base font-medium text-gray-100">{t('home')}</span>
                    </a>
                    <a
                      key='contact'
                      href='/contact'
                      className="-m-3 flex items-center rounded-md p-3 hover:bg-cyan-600"
                    >
                      <EnvelopeIcon className="h-6 w-6 flex-shrink-0 text-white" aria-hidden="true" />
                      <span className="ml-4 text-base font-medium text-gray-100">{t('contact')}</span>
                    </a>
                    <span className="-m-3 flex items-center rounded-md p-3 hover:bg-cyan-600">
                      <LanguageIcon className="h-6 w-6 flex-shrink-0 text-white" aria-hidden="true" />
                      <span className="ml-1 w-50 text-base font-medium text-gray-100"><DropDown /></span>
                    </span>
                </nav>
              </div>
            </div>
          </div>
        </Popover.Panel>
      </Transition>
    </Popover>
  )
}
