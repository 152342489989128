import { EnvelopeIcon, PhoneIcon, HomeIcon } from '@heroicons/react/24/outline'
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { useState } from 'react';
import Notification from './Notification';

export default function ContactSection() {

    const { t } = useTranslation()
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [subject, setSubject] = useState('')
    const [phone, setPhone] = useState('')
    const [message, setMessage] = useState('')
    const [error, setError] = useState(false)
    const [disabled, setDisabled] = useState(false)
    const [show, setShow] = useState(false)
    const emailRegex = RegExp('^[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+.[a-zA-Z]$');
    const phoneRegex = RegExp('^[+]?[(]?[0-9]{3}[)]?[-s.]?[0-9]{3}[-s.]?[0-9]{4}$');

    // Notication variables
    const [notiTitle, setNotiTitle] = useState('')
    const [notiMessage, setNotiMessage] = useState('')
    const [success, setSuccess] = useState(null)

    const handleSubmit = async (e) => {
        setDisabled(true)
        let canSubmit = true
        e.preventDefault()
        if(name.length < 3 || message.length < 3 || subject.length < 3) {
            canSubmit = false
            setError(true)
        }
        if(!emailRegex.test(email)) {
            canSubmit = false
            setError(true)
        }
        if(phone.length > 0 && !phoneRegex.test(phone)) {
            canSubmit = false
            setError(true)
        }

        if(canSubmit) {

            const emailObject = {
            name: name,
            from_email: email,
            to_email: process.env.REACT_APP_TO_EMAIL,
            email_subject: subject,
            email_body: message,
            phone: phone,
            }

            await axios.post(process.env.REACT_APP_EMAIL_PROXY_API_URL, emailObject).then( res => {
                if(res.status === 200 || res.status === 201) {
                    setSuccess(true)
                    setNotiTitle(t('notificationSuccessTitle'))
                    setNotiMessage(t('notificationSuccessMessage'))
                } else {
                    setSuccess(false)
                    setNotiTitle(t('notificationErrorTitle'))
                    setNotiMessage(t('notificationErrorMessage'))
                }

                setShow(true)
                setTimeout(() => {
                    setShow(false)
                    reset()
                }, '3000')

            })

        }
    }

    const reset = () => {
        // Reset Form Variables
        setName('')
        setEmail('')
        setSubject('')
        setPhone('')
        setMessage('')
        setError(false)
        setDisabled(false)

        // Reset Notification Variables
        setNotiTitle('')
        setNotiMessage('')
        setSuccess(null)
    }


    return (
        <>
        <Notification 
        show={show} 
        setShow={setShow}
        title={notiTitle}
        message={notiMessage}
        success={success}
        />
        <main className="overflow-hidden">
        {/* Header */}
        <div className="bg-warm-gray-50">
        <div className="py-24 lg:py-32">
            <div className="relative z-10 mx-auto max-w-7xl pl-4 pr-8 sm:px-6 lg:px-8">
            <h1 className="text-4xl font-bold tracking-tight text-warm-gray-900 sm:text-5xl lg:text-6xl">
            {t('contact')}
            </h1>
            <p className="mt-6 max-w-3xl text-xl text-warm-gray-500">
            {t('contactDescription')}
            </p>
            </div>
        </div>
        </div>

        {/* Contact section */}
        <section className="relative bg-white" aria-labelledby="contact-heading">
        <div className="absolute h-1/2 w-full bg-warm-gray-50" aria-hidden="true" />
        {/* Decorative dot pattern */}
        <div className="relative mx-auto max-w-7xl px-6 lg:px-8">
            <svg
            className="absolute top-0 right-0 z-0 -translate-y-16 translate-x-1/2 transform sm:translate-x-1/4 md:-translate-y-24 lg:-translate-y-72"
            width={404}
            height={384}
            fill="none"
            viewBox="0 0 404 384"
            aria-hidden="true"
            >
            <defs>
                <pattern
                id="64e643ad-2176-4f86-b3d7-f2c5da3b6a6d"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
                >
                <rect x={0} y={0} width={4} height={4} className="text-warm-gray-200" fill="currentColor" />
                </pattern>
            </defs>
            <rect width={404} height={384} fill="url(#64e643ad-2176-4f86-b3d7-f2c5da3b6a6d)" />
            </svg>
        </div>
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
            <div className="relative bg-white shadow-xl">
            <h2 id="contact-heading" className="sr-only">
                Contact us
            </h2>

            <div className="grid grid-cols-1 lg:grid-cols-3 mb-24">
                {/* Contact information */}
                <div className="relative overflow-hidden bg-gradient-to-b from-cyan-800 to-cyan-900 py-10 px-6 sm:px-10 xl:p-12">
                {/* Decorative angle backgrounds */}
                <div className="pointer-events-none absolute inset-0 sm:hidden" aria-hidden="true">
                    <svg
                    className="absolute inset-0 h-full w-full"
                    width={343}
                    height={388}
                    viewBox="0 0 343 388"
                    fill="none"
                    preserveAspectRatio="xMidYMid slice"
                    xmlns="http://www.w3.org/2000/svg"
                    >
                    <path
                        d="M-99 461.107L608.107-246l707.103 707.107-707.103 707.103L-99 461.107z"
                        fill="url(#linear1)"
                        fillOpacity=".1"
                    />
                    <defs>
                        <linearGradient
                        id="linear1"
                        x1="254.553"
                        y1="107.554"
                        x2="961.66"
                        y2="814.66"
                        gradientUnits="userSpaceOnUse"
                        >
                        <stop stopColor="#fff" />
                        <stop offset={1} stopColor="#fff" stopOpacity={0} />
                        </linearGradient>
                    </defs>
                    </svg>
                </div>
                <div
                    className="pointer-events-none absolute top-0 right-0 bottom-0 hidden w-1/2 sm:block lg:hidden"
                    aria-hidden="true"
                >
                    <svg
                    className="absolute inset-0 h-full w-full"
                    width={359}
                    height={339}
                    viewBox="0 0 359 339"
                    fill="none"
                    preserveAspectRatio="xMidYMid slice"
                    xmlns="http://www.w3.org/2000/svg"
                    >
                    <path
                        d="M-161 382.107L546.107-325l707.103 707.107-707.103 707.103L-161 382.107z"
                        fill="url(#linear2)"
                        fillOpacity=".1"
                    />
                    <defs>
                        <linearGradient
                        id="linear2"
                        x1="192.553"
                        y1="28.553"
                        x2="899.66"
                        y2="735.66"
                        gradientUnits="userSpaceOnUse"
                        >
                        <stop stopColor="#fff" />
                        <stop offset={1} stopColor="#fff" stopOpacity={0} />
                        </linearGradient>
                    </defs>
                    </svg>
                </div>
                <div
                    className="pointer-events-none absolute top-0 right-0 bottom-0 hidden w-1/2 lg:block"
                    aria-hidden="true"
                >
                    <svg
                    className="absolute inset-0 h-full w-full"
                    width={160}
                    height={678}
                    viewBox="0 0 160 678"
                    fill="none"
                    preserveAspectRatio="xMidYMid slice"
                    xmlns="http://www.w3.org/2000/svg"
                    >
                    <path
                        d="M-161 679.107L546.107-28l707.103 707.107-707.103 707.103L-161 679.107z"
                        fill="url(#linear3)"
                        fillOpacity=".1"
                    />
                    <defs>
                        <linearGradient
                        id="linear3"
                        x1="192.553"
                        y1="325.553"
                        x2="899.66"
                        y2="1032.66"
                        gradientUnits="userSpaceOnUse"
                        >
                        <stop stopColor="#fff" />
                        <stop offset={1} stopColor="#fff" stopOpacity={0} />
                        </linearGradient>
                    </defs>
                    </svg>
                </div>
                <h3 className="text-lg font-medium text-white">{t('contactInformation')}</h3>
                <dl className="mt-8 space-y-6">
                    <dt>
                    <span className="sr-only">Address</span>
                    </dt>
                    <dd className="flex text-base text-cyan-50">
                    <HomeIcon className="h-6 w-6 flex-shrink-0 text-cyan-200" aria-hidden="true" />
                    <span className="ml-3">1150 NW 72nd Ave, Unit 455 <br /> Miami, FL 33126</span>
                    </dd>
                    <dt>
                    <span className="sr-only">Phone number</span>
                    </dt>
                    <dd className="flex text-base text-cyan-50">
                    <PhoneIcon className="h-6 w-6 flex-shrink-0 text-cyan-200" aria-hidden="true" />
                    <span className="ml-3">386-349-3663</span>
                    </dd>
                    <dt>
                    <span className="sr-only">Email</span>
                    </dt>
                    <dd className="flex text-base text-cyan-50">
                    <EnvelopeIcon className="h-6 w-6 flex-shrink-0 text-cyan-200" aria-hidden="true" />
                    <span className="ml-3">support@staticserved.com</span>
                    </dd>
                </dl>
                <ul className="mt-8 flex space-x-12">
                    <li>
                    <a className="text-cyan-200 hover:text-cyan-100" href="https://www.facebook.com/staticserved">
                        <span className="sr-only">Facebook</span>
                        <svg className="h-7 w-7" aria-hidden="true" fill="currentColor" viewBox="0 0 24 24">
                        <path
                            fillRule="evenodd"
                            d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
                            clipRule="evenodd"
                        />
                        </svg>
                    </a>
                    </li>
                    <li>
                    <a className="text-cyan-200 hover:text-cyan-100" href="https://www.instagram.com/staticserved/">
                        <span className="sr-only">Instagram</span>
                        <svg className="h-7 w-7" aria-hidden="true" fill="currentColor" viewBox="0 0 24 24">
                        <path
                            fillRule="evenodd"
                            d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
                            clipRule="evenodd"
                        />
                        </svg>
                    </a>
                    </li>
                    <li>
                    <a className="text-cyan-200 hover:text-cyan-100" href="https://twitter.com/StaticServed">
                        <span className="sr-only">Twitter</span>
                        <svg className="h-7 w-7" aria-hidden="true" fill="currentColor" viewBox="0 0 24 24">
                        <path 
                        fillRule="evenodd"
                        d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84"
                        clipRule="evenodd"
                        />
                        </svg>
                    </a>
                    </li>
                </ul>
                </div>

                {/* Contact form */}
                <div className="py-10 px-6 sm:px-10 lg:col-span-2 xl:p-12 bg-gray-100">
                <h3 className="text-lg font-medium text-warm-gray-900">{t('messagePlaceholder')}</h3>
                <form onSubmit={handleSubmit} method="POST" className="mt-6 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8">
                    <div>
                    <label htmlFor="name" className="block text-sm font-medium text-warm-gray-900">
                    {t('name')} <span className='text-red-500'>*</span>
                    </label>
                    <div className="mt-1">
                        <input
                        type="text"
                        name="name"
                        id="name"
                        autoComplete="given-name"
                        placeholder={t('namePlaceholder')}
                        value={name}
                        onChange={e => setName(e.target.value)}
                        required
                        className="block w-full rounded-md border-warm-gray-300 py-3 px-4 text-warm-gray-900 shadow-sm focus:border-cyan-800 focus:ring-cyan-800"
                        />
                        { error && name.length < 3 ? <p className='mt-1 ml-1 text-xs text-red-500'>Error! {t('nameValidation')}</p> : ''}
                    </div>
                    </div>
                    <div>
                    <div className="flex justify-between">
                        <label htmlFor="phone" className="block text-sm font-medium text-warm-gray-900">
                        {t('phone')}
                        </label>
                        <span id="phone-optional" className="text-sm text-warm-gray-500">
                        {t('optional')}
                        </span>
                    </div>
                    <div className="mt-1">
                        <input
                        type="text"
                        name="phone"
                        id="phone"
                        autoComplete="tel"
                        placeholder={t('phonePlaceholder')}
                        value={phone}
                        onChange={e => setPhone(e.target.value)}
                        className="block w-full rounded-md border-warm-gray-300 py-3 px-4 text-warm-gray-900 shadow-sm focus:border-cyan-800 focus:ring-cyan-800"
                        aria-describedby="phone-optional"
                        />
                        { error && (phone.length > 0 && !phoneRegex.test(phone)) ? <p className='mt-1 ml-1 text-xs text-red-500'>Error! {t('phoneValidation')}</p> : '' }
                    </div>
                    </div>
                    <div className="sm:col-span-2">
                    <label htmlFor="email" className="block text-sm font-medium text-warm-gray-900">
                    {t('email')} <span className='text-red-500'>*</span>
                    </label>
                    <div className="mt-1">
                        <input
                        id="email"
                        name="email"
                        type="email"
                        autoComplete="email"
                        placeholder={t('emailPlaceholder')}
                        value={email}
                        onChange={e => setEmail(e.target.value)}
                        required
                        className="block w-full rounded-md border-warm-gray-300 py-3 px-4 text-warm-gray-900 shadow-sm focus:border-cyan-800 focus:ring-cyan-800"
                        />
                        { error && !emailRegex.test(email) ? <p className='mt-1 ml-1 text-xs text-red-500'>Error! {t('emailValidation')}</p> : '' }
                    </div>
                    </div>
                    <div className="sm:col-span-2">
                    <label htmlFor="subject" className="block text-sm font-medium text-warm-gray-900">
                    {t('subject')} <span className='text-red-500'>*</span>
                    </label>
                    <div className="mt-1">
                        <input
                        type="text"
                        name="subject"
                        id="subject"
                        placeholder={t('subjectPlaceholder')}
                        value={subject}
                        onChange={e => setSubject(e.target.value)}
                        required
                        className="block w-full rounded-md border-warm-gray-300 py-3 px-4 text-warm-gray-900 shadow-sm focus:border-cyan-800 focus:ring-cyan-800"
                        />
                        { error && subject.length < 3 ? <p className='mt-1 ml-1 text-xs text-red-500'>Error! {t('subjectValidation')}</p> : ''}
                    </div>
                    </div>
                    <div className="sm:col-span-2">
                    <div className="flex justify-between">
                        <label htmlFor="message" className="block text-sm font-medium text-warm-gray-900">
                        {t('message')} <span className='text-red-500'>*</span>
                        </label>
                    </div>
                    <div className="mt-1">
                        <textarea
                        id="message"
                        name="message"
                        rows={4}
                        className="block w-full rounded-md border-warm-gray-300 py-3 px-4 text-warm-gray-900 shadow-sm focus:border-cyan-800 focus:ring-cyan-800"
                        aria-describedby="message-max"
                        placeholder={t('messagePlaceholder')}
                        value={message}
                        onChange={e => setMessage(e.target.value)}
                        required
                        />
                        { error && message.length < 3 ? <p className='mt-1 ml-1 text-xs text-red-500'>Error! {t('messageValidation')}</p> : '' }
                    </div>
                    </div>
                    <div className="sm:col-span-2 sm:flex sm:justify-end">
                    <button
                        type="text"
                        className="mt-2 mr-2 inline-flex w-full items-center justify-center rounded-md border border-transparent bg-red-500 px-6 py-3 text-base font-medium text-white shadow-sm hover:bg-red-900 focus:outline-none focus:ring-2 focus:ring-cyan-800 focus:ring-offset-2 sm:w-auto"
                        onClick={reset}
                    >
                        {t('clear')}
                    </button>
                    <button
                        type="submit"
                        disabled={disabled}
                        className="mt-2 inline-flex w-full items-center justify-center rounded-md border border-transparent bg-cyan-800 px-6 py-3 text-base font-medium text-white shadow-sm hover:bg-cyan-900 focus:outline-none focus:ring-2 focus:ring-cyan-800 focus:ring-offset-2 sm:w-auto"
                    >
                        {t('send')}
                    </button>
                    </div>
                </form>
                </div>
            </div>
            </div>
        </div>
        </section>
        </main>
        </>
    )
  }

