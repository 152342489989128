import Technologies from "../components/Technologies";
import Header from "../components/Header";
import SoftwareDevelopment from "../components/SoftwareDevelopment";
import MachineLearningAI from "../components/MachineLearningAI";
import ConsultingServices from "../components/ConsultingServices";
import CTA from "../components/CTA";


export default function Home() {
    return (
        <div className="bg-cyan-900">
            <Header />
            <Technologies />
            <SoftwareDevelopment />
            <ConsultingServices />
            <MachineLearningAI />
            <CTA />
        </div>
    )
}