export default function Technologies() {
    return (
      <div className="bg-gray-100 py-24 sm:py-32">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="grid grid-cols-1 items-center gap-x-8 gap-y-16 lg:grid-cols-2">
            <div className="mx-auto w-full max-w-xl lg:mx-0">
              <h2 className="text-3xl font-bold tracking-tight text-gray-900">Innovative Technologies</h2>
              <p className="mt-6 text-lg leading-8 text-gray-600">
              Known for our commitment to innovation and staying up-to-date with the latest industry trends. 
              We leverage cutting-edge AI-driven solutions and embraces cloud-native architectures to provide clients with forward-thinking software development, machine learning, and consulting services.
              </p>
              <div className="mt-8 flex items-center gap-x-6">
                <a href="/contact" className="text-sm font-semibold text-gray-900">
                  Contact us <span aria-hidden="true">&rarr;</span>
                </a>
              </div>
            </div>
            <div className="mx-auto grid w-full max-w-xl grid-cols-3 items-center gap-y-12 sm:gap-y-14 lg:mx-0 lg:max-w-none lg:pl-8">
              <img
                className="max-h-10 w-full md:max-h-14 lg:max-h-16 object-contain object-center"
                src="/img/angular.png"
                alt="Tuple"
                width={105}
                height={48}
              />
              <img
                className="max-h-10 w-full md:max-h-14 lg:max-h-16 object-contain object-center"
                src="/img/react.png"
                alt="Reform"
                width={104}
                height={48}
              />
              <img
                className="max-h-10 w-full md:max-h-14 lg:max-h-16 object-contain object-center"
                src="/img/tsql.png"
                alt="SavvyCal"
                width={140}
                height={48}
              />
              <img
                className="max-h-10 w-full md:max-h-14 lg:max-h-16 object-contain object-center"
                src="/img/asp-net.png"
                alt="Laravel"
                width={136}
                height={48}
              />
              <img
                className="max-h-10 w-full md:max-h-14 lg:max-h-16 object-contain object-center"
                src="/img/net-core.png"
                alt="Transistor"
                width={158}
                height={48}
              />
              <img
                className="max-h-10 w-full md:max-h-14 lg:max-h-16 object-contain object-center"
                src="/img/python.png"
                alt="Statamic"
                width={147}
                height={48}
              />
              <img
                className="max-h-10 w-full md:max-h-14 lg:max-h-16 object-contain object-center"
                src="/img/nodejs.png"
                alt="Laravel"
                width={136}
                height={48}
              />
              <img
                className="max-h-10 w-full md:max-h-14 lg:max-h-16 object-contain object-center"
                src="/img/aws.png"
                alt="Transistor"
                width={158}
                height={48}
              />
              <img
                className="max-h-10 w-full md:max-h-14 lg:max-h-16 object-contain object-center"
                src="/img/azure.png"
                alt="Statamic"
                width={147}
                height={48}
              />
            </div>
          </div>
        </div>
      </div>
    )
  }
  